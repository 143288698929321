.about{
    text-align: center;

    .about-head{
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        h2{
            color: var(--negro);
            font-size: 2rem;
            text-transform: uppercase;
        }

        .divider{
            width: 100px;
            height: 2px;
            border-radius: 10px;
            background-color: var(--rojo);
        }
    }

    .about-content{
        display: flex;
        padding: 50px;
        padding-bottom: 10px;
        gap: 60px;
        align-items: center;
        justify-content: center;

        .about-image{
            width: 40vw;

            img{
                width: 100%;
            }
            @media screen and (max-width:770px) {
                width: 80%;
            }
        }

        .about-text{
            width: 40vw;

            
        h2{
            font-size: 1.2rem;
            color: var(--negro);
            span{
                color: var(--rojo);
            }
        }
        h3{
            color: var(--negro);
            font-size: 1.5rem;
            font-weight: bold;
            margin: 10px;
        }

            p{
                font-size: 1.1rem;
                color: var(--gris);
            }

            @media screen and (max-width:770px) {
                width: 100%;
            }
            @media screen and (min-width:1400px) {
                h2{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.5rem;
                }
            }

            ul{

                li{
                    font-size: 1.2rem;
                    margin: 10px;
                }
            }
        }

        @media screen and (max-width:770px) {
            flex-direction: column;
            align-items: center;
        }
    }
    .about-content-2{
        display: flex;
        padding: 0 50px;
        gap: 60px;
        align-items: center;
        justify-content: center;

        .about-image{
            width: 50vw;

            img{
                width: 100%;
            }
            @media screen and (max-width:770px) {
                width: 80%;
            }
        }

        .about-text-2{
            width: 50vw;

            
        h2{
            font-size: 1.2rem;
            color: var(--negro);
            span{
                color: var(--rojo);
            }
        }

            p{
                font-size: 1.2rem;
                color: var(--gris);
            }

            @media screen and (max-width:770px) {
                width: 100%;
            }
            @media screen and (min-width:1400px) {
                h2{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.5rem;
                }
            }

            ul{
                li{
                    margin-top: 10px;
                }
                .link{
                    font-size: 1.2rem;
                    font-weight: bold;
                    color: var(--rojo);
                    
                }
            }
        }

        @media screen and (max-width:770px) {
            flex-direction: column;
            align-items: center;
        }
    }   
    
}