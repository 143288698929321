.services{
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    // height: 100vh;

    .services__head{
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        h2{
            color: var(--negro);
            font-size: 2rem;
            text-transform: uppercase;
        }

        .divider{
            width: 100px;
            height: 2px;
            border-radius: 10px;
            background-color: var(--rojo);
        }
    }
    .services-content{
        display: flex;
        padding: 20px;
        padding-bottom: 10px;
        gap: 20px;
        align-items: center;
        justify-content: center;

        .services-image{
            width: 38%;
            height: 70vh;
            background: url(../../assets/camion-maca.jpg);
            background-size: cover;

            display: flex;
            align-items: center;

            p{
                color: aliceblue;
                font-size: 1.5rem;
                font-weight: bold;
                padding: 20px;
            }

            // img{
            //     width: 100%;
            // }

            @media screen and (max-width:770px) {
                width: 100%;
            }
        }

        .services-text{
            width: 80%;

            ul{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 50px;


                li{
                    margin-top: 25px;
    
                    .link{
                        font-size: 1rem;
                        color: var(--negro);
                        text-transform: uppercase;
                        font-weight: bold;
                        transition: all .5s;
                        

                        &:hover{
                            color: var(--rojo);
                        }

                        @media screen and (min-width:1400px) {
                            font-size: 1.3rem;
                        }
                    }
                    svg{
                        color: var(--rojo);
                    }
                }
            }

            @media screen and (max-width:770px) {
                width: 100%;

                ul{
                    // padding: 0;
                    align-items: center;
                }
            }
        }

        @media screen and (max-width:770px) {
            flex-direction: column;
            align-items: center;
        }
        @media screen and (min-width:1400px) {
            padding: 50px;
        }
    }

    .services-content-2{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 50px;

        .services-image-2{
            width: 40%;

            img{
                width: 100%;
            }

            @media screen and (max-width:770px) {
                width: 80%;
            }
        }
        .services-text-2{
            width: 50%;
            font-size: 1.5rem;
            color: var(--gris);

            @media screen and (max-width:770px) {
                width: 80%;
            }
            @media screen and (max-width:550px) {
                width: 100%;
            }
            @media screen and (min-width:1400px) {
                font-size: 2rem;
            }
        }

        @media screen and (max-width:770px) {
            flex-direction: column;
            align-items: center;
        }
        @media screen and (min-width:1400px) {
            padding: 50px;
        }
    }
}