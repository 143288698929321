.servicio{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .servicio-head{
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        h2{
            color: var(--negro);
            font-size: 2rem;
            text-transform: uppercase;
        }

        .divider{
            width: 100px;
            height: 2px;
            border-radius: 10px;
            background-color: var(--rojo);
        }
    }
    .servicio-content{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 50px;

        .servicio-image{
            width: 40%;

            img{
                width: 100%;
            }

            @media screen and (max-width:770px) {
                width: 100%;
            }
        }
        .servicio-text{
            width: 60%;

            h3{
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            p{
                font-size: 1.3rem;
            }

            @media screen and (max-width:770px) {
                width: 100%;
            }
            @media screen and (max-width:550px) {
                h3{
                    font-size: 1.2rem;
                }
                p{
                    font-size: 1rem;
                }
            }
            @media screen and (min-width:1400px) {
                h3{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.5rem;
                }
            }
        }

        @media screen and (max-width:770px) {
            flex-direction: column;
        }
    }

}