@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
  font-family: 'Lato', sans-serif;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
::selection {
  color: aliceblue;
  background: rgb(141, 1, 1);
}