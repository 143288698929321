.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}
.elegirnos{
    margin: 50px;
    background: url(../../assets/mechanic.jpg);
    background-size: cover;
    width: 90%;
    height: 80vh;
    position: relative;

    .elegirnos-bg{
        width: 100%;
        height: 100%;
        background-color: rgba(244, 63, 65, 0.3); 
        z-index: 3;
    }

    .elegirnos-content{
        width: 100%;
        height: 100%;
        z-index: 33;
        position: absolute;
        gap: 50px;

        display: flex;
        align-items: center;
        padding: 20px;
        

        .elegirnos-image{
            width: 50%;

            img{
                width: 100%;
            }
        }
        .elegirnos-text{
            width: 50%;
            color: aliceblue;
            // padding: 20px;
            
            h2{
                font-size: 2rem;
                text-transform: uppercase;
            }
        }

        p{
            font-size: 1.2rem;
            margin: 10px;
        }
    }
}
@media screen and (max-width:550px) {
    .elegirnos{
        height: 100vh;

        .elegirnos-content{
            flex-direction: column;

            .elegirnos-image{
                width: 100%;
            }
            .elegirnos-text{
                width: 100%;
            }
        }
    }
}