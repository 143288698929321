.app__navbar {
    width: 100%;
    height: 80px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
    z-index: 2;
    text-align: center;
    background-color: var(--negro);
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    
}
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 180px;
      height: 65px;
  
      @media screen and (min-width: 2000px) {
        width: 360px;
        height: 100px;
      }
    }

    @media screen and (max-width:900px) {
        display: block;
    }
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  
    list-style: none;
  
    li {
      margin: 0 1rem;
      cursor: pointer;
  
      flex-direction: column;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
  
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
      }
  
      .link {
        color: var(--blanco);
        text-decoration: none;
        flex-direction: column;
        font-size: 1rem;
  
        text-transform: uppercase;
        font-weight: 500;
  
        transition: all 0.3s ease-in-out;
        position: relative;

        font-weight: bold;
  
        &:hover {
          color: white;
        }
      }
      .link:after{
        content: "";
        position: absolute;
        background-color: var(--rojo);
        height: 3px;
        width: 0;
        left: 0;
        bottom: -10px;
        transition: 0.3s;
      }
      .link:hover:after{
        width: 100%;
      }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width:900px) {
        display: none;
    }
  
    svg {
      width: 35px;
      height: 35px;
      color: var(--rojo);
    }

    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 100%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        background-color: var(--negro);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid rgba(255, 255, 255, 0.18);

        svg{
            width: 35px;
            height: 35px;
            color: var(--rojo);
        }
        
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            li{
                margin: 2rem;

                .link{
                    color: var(--blanco);
                    text-decoration: none;
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    font-weight: bold;

                    transition: all 0.3s ease-in-out;
  
                   &:hover {
                     color: var(--rojo);
                    }
                }
            }

            .icon{
              padding: 0.5rem;

              svg{
                width: 1.5rem;
              }
              svg:hover{
                color: var(--blanco);
              }
            }
        }
    }
}