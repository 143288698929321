.footer{
    // margin: 100px 0 20px 0;
    background-color: var(--negro);
    color: aliceblue;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .top{
        display: flex;
        justify-content: center;
        gap: 50px;
        padding: 20px;

        .item{
            flex-direction: column;
            gap: 10px;
            text-align: justify;
            font-size: 1rem;

            h3{
                font-size: 1.8rem;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            p{
                font-size: 0.6rem;
                text-transform: uppercase;
                padding: 5px;

                @media screen and (min-width:1400px) {
                    font-size: 0.8rem;
                }
            }

            ul li{
                text-transform: uppercase;
                padding: 5px;
                list-style: none;

                .link{
                    font-size: 0.5rem;
                    font-weight: bold;
                    color: var(--blanco);
                    transition: all .3s ;

                    &:hover{
                        color: var(--rojo);
                    }
                    @media screen and (min-width:1400px) {
                        font-size: 0.8rem;
                    }
                    
                }
            }

            .btn{
                background-color: var(--rojo);
                border: none;
                border-radius: 5px;
                padding: 10px;
                margin: 5px;
                color: aliceblue;
                cursor: pointer;

                text-transform: uppercase;

                &:hover{
                    background-color: rgb(165, 0, 0);
                }
                #link{
                    color: aliceblue;
                }
            }

        }
        #item-1{
            width: 150px;

            img{
                width: 100%;
            }
        }
    }
    .bottom{
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            padding: 10px;

            @media screen and (max-width:550px) {
                font-size: 0.6rem;
            }
        }

        span{
            width: 80%;
            height: 2px;
            background-color: var(--rojo);

            display: block;
        }
    }

}
@media screen and (max-width:770px) {
    .top{
        flex-direction: column;
    }
}