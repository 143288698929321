.contact{

    .contact-head{
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        h2{
            color: var(--negro);
            font-size: 2rem;
            text-transform: uppercase;
        }

        .divider{
            width: 100px;
            height: 2px;
            border-radius: 10px;
            background-color: var(--rojo);
        }
    }
    .contact__content{
        display: flex;
        flex-direction: column;
        align-items: center;

        .contact__content-map{
            width: 70vw;
        }
        .contact__content-form{
            display: flex;
            width: 70%;
            gap: 50px;
            padding: 50px;

            @media screen and (max-width:800px) {
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            .item{
                display: flex;
                align-items: center;
                gap: 20px;
                margin: 50px;

                width: 50%;

                span{
                    background-color: rgb(230, 240, 250);
                    padding: 10px;
                    border-radius: 50%;
                    transition: all .5s;

                    &:hover{
                        background-color: var(--rojo);
                    }

                    svg{
                        color: var(--rojo);

                        &:hover{
                            color: aliceblue;
                        }
                    }
                }
                .item-info{
                    h3{
                        text-transform: uppercase;
                        color: var(--negro);
                    }
                    p{
                        color: var(--gris);
                    }
                }
            }

            .form-container{
                width: 50%;

                // background-color: red;

                @media screen and (max-width:800px) {
                    width: 100%;
                }

                form{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                input{
                    border: 1px solid #595959;
                    border-radius: 5px;
                    padding: 6px 12px;

                }

                textarea {
                    resize: none;
                 }
                 button{
                    padding: 6px 12px;
                    border-radius: 5px;
                    border: none;

                    background-color: var(--rojo);
                    color: aliceblue;
                    cursor: pointer;

                    &:hover{
                        background-color: rgb(174, 0, 0);
                    }
                 }
            }
        }
    }
}