#header{
    width: 100%;
    height: 100%;
    padding: 100px 20px;

    background: radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%);

    .header__container{
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media screen and (max-width:750px) {
            flex-direction: column-reverse;

            .header__container-text{
                padding: 10px;
                width: 100%;
            }

            .header__container-img{
                width: 100%;

                img{
                    width: 100%;
                }
            }
        }
        @media screen and (min-width:1400px) {
            h2{
                font-size: 3rem;
            }
            h3{
                font-size: 1.8rem;
            }
        }
    }

}
.header__container-text{
    width: 50%;
    padding: 20px;
    text-transform: uppercase;
    text-align: center;
    color: aliceblue;

    h2{
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 2px;
        padding: 10px;
    }
    h3{
        font-size: 1rem;
    }

    .header__container-text-btn{
        display: flex;
        justify-content: center;
    }

    .btn{
        background-color: aliceblue;
        border: none;
        color: var(--rojo);
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 30px;
        margin: 50px;
        border-radius: 20px;
        cursor: pointer;

        &:hover{
            background-color: rgb(230, 231, 231);
        }
        .link{
            color: var(--rojo);
        }
    }
    #wpp{
        background-color: #25D366;
        color: aliceblue;
        border: none;
        font-size: 1rem;
        padding: 12px;
        margin: 50px;
        border-radius: 50%;
        cursor: pointer;

        svg{
            font-size: 2rem;
        }

        &:hover{
            background-color: aliceblue;
            color: #25D366;
        }
    }

}

.header__container-img{
    width: 50%;
    
    transition: 0.5s;

    img{
        width: 100%;
    }
    &:hover{
        transform: translateY(-50px);
    }
}