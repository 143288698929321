:root{
    --rojo:#D21312;
    --negro:#212125;
    --blanco:#c6c6c6;
    --gris:#454545;
  }

  section{
    margin-top: 80px;
    padding-bottom: 50px;
  }
  // h1,h2,h3,h2{
  //   color: var(--negro);
  // }